import { Component } from 'react';
import "../../styles/points.css";
import { getAdminData } from '../../utils/common.utils';

const mainHeader = {
  textAlign: 'center', background: 'transparent', marginBottom: '0cm', lineHeight: '100%', fontWeight: '500',  fontSize: '20px'
}

const sideHeader = {
  textAlign: 'justify', background: 'transparent', marginBottom: '0cm', fontWeight: '400',  fontSize: '16px', lineHeight: '20px'
}

const normalText = {
  textAlign: 'justify', background: 'transparent', marginBottom: '0cm',  fontSize: '16px', lineHeight: '20px', fontWeight: '300'
}

export default class TermsAndConditions extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
    }

    // this.adminData = getAdminData();

  }

  render() {

    // const rules = this.adminData?.termsAndConditions?.filter(val => val.type == 'SIGN_UP_PAGE');

    return(
      <section className="body-section">
               
          {/* <div className="p-3 d-flex flex-column mx-auto notifications">
            <div className="row text-center">
                <div className="col-12">
                  <h1 className="fs-20 fw-bold text-white mb-2 mt-3">Terms and Conditions</h1>
                </div>
               
            </div>
            <div className='row '>
              <ol className='text-white'>
                {rules[0]?.rule?.map((rule,i)=>{
                  return <li key={i}>{rule.description}</li>
                })}
              </ol>
            </div>
          </div> */}
          <div className="p-3 d-flex flex-column mx-auto notifications">
            <p style={mainHeader}><strong>Terms &amp; Conditions</strong></p>
            <p style={sideHeader}><br /></p>
            <p style={sideHeader}>IMPORTANT: PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE.</p>
            <p style={sideHeader}><br /></p>
            <p style={sideHeader}>THESE TERMS AND CONDITIONS ARE DRAFTED IN ENGLISH. ANY TRANSLATION INTO ANOTHER LANGUAGE IS ONLY FOR THE CONVENIENCE OF THE READER. IN THE EVENT OF ANY CONFLICT OR INCONSISTENCY THE ENGLISH LANGUAGE VERSION SHALL PREVAIL OVER ANY TRANSLATED VERSION.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>General Terms and Conditions</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>1. Who We Are and How We Are Regulated</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}><span size="3" style={{fontSize: '16px'}}>1.1. eSpotz Inc (&quot;eSpotz&quot;, &ldquo;we&rdquo; or &ldquo;us&rdquo;) is established in the Canada with Registered ID 4363755 and has a registered office located at Suite 1300, 2000 Barrington Street, Halifax, Nova Scotia, B3J 3K1 Canada. &nbsp;</span></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>1.2. To contact us, please email support@espotz.online</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>2. When These Terms and Conditions Apply</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>2.1. These Terms and Conditions together with the Privacy Policy the Promotional Terms and Conditions which apply between eSpotz and any person accessing or using (&ldquo;you&rdquo;) any of the products and services (&quot;Services&quot;) offered through eSpotz&rsquo;s website (located at: <a href="https://espotz.online/" target="_blank">https://www.espotz.online</a>)(&quot;Website&quot;).</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>2.2. These Terms and Conditions form a legally binding agreement between you and eSpotz. By registering an account (&ldquo;Your Account&rdquo;) or (&ldquo;Account&rdquo;), utilising the Services or by proceeding to access the Website, you are deemed to have accepted and understood them. &nbsp;You agree to be bound by the Terms and Conditions and You agree to the use of electronic communications in order to enter into contracts and, to the extent permitted by law, waive any applicable rights requiring signature by hand.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>2.3. These Terms and Conditions will come into effect on Oct 20, 2024.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>3. Changes to the Terms and Conditions</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>3.1. We may need to amend the Terms and Conditions from time to time for a number of reasons, including (without limitation) for commercial reasons, to comply with law or regulations, to comply with instructions, guidance or recommendations from a regulatory body, or for customer service reasons. The most up-to-date Terms and Conditions can be accessed from the link in the footer section of the Website, and the date on which they will come into force will be noted at paragraph 2.3.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>3.2. Where we wish to make substantial changes to the Terms and Conditions, we will give you as much prior notice of such changes as is reasonably practicable via one of the following methods:</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>(a) Email (to the email address you have supplied us with);</p>
            <p style={normalText}>(b) A message to Your Account on the Website; or</p>
            <p style={normalText}>(c) A notice on the Website.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>3.3. For minor or insubstantial changes, we may not give you any notice of such changes, so you are advised to review the Terms and Conditions through the link on the Website on a regular basis. Your continued use of the Website after these changes have been made shall be deemed as your acceptance of the changes</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>3.4. We may, at our discretion, invite you to accept any new Terms and Conditions by clicking on &quot;yes&quot; or &quot;I accept&quot;, checking a &apos;tick box&apos; or any other similar method of confirmation by you. If you provide us with an acknowledgment in accordance with paragraph 3.4 above or if you continue to use Your Account or otherwise access the Website following notification of a change to the Website, you shall, from such time, be deemed to have accepted, and be bound by, the new Terms and Conditions. &nbsp;If any change is unacceptable to you, you may either cease using the Website and/or close Your Account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>4. Verification of Identity</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>4.1. By accepting these Terms and Conditions you warrant that the name and address you supply when opening Your Account are correct.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>4.2. By agreeing to these Terms and Conditions you authorize us to undertake any such verification checks from time to time as we may require ourselves or may be required by third parties (including, but not limited to, regulatory bodies) to confirm these facts (the &quot;IDChecks&quot;). You agree that from time to time, upon our request, you may be required to provide additional details in respect of any of such information you have provided us, including in relation to any deposits which you have made into Your Account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>4.3. In accordance with our Privacy Policy, you accept that we may from time to time and at our sole discretion, utilize the services of third parties to validate the Personal Information. &nbsp;By accepting these Terms and Conditions you consent to the ID Checks being made by or with these third parties.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>4.3. In certain circumstances we may have to contact you and ask you to provide further information to us directly in order to complete the ID Checks. For this purpose, we will be entitled, at our sole discretion, to require that you provide us with identification documentation certified or notarize in accordance with the applicable law of your jurisdiction of residence. &nbsp;Such documentation may include proof of address, utility bills, bank details, bank statements and bank references. Until such information has been supplied to our satisfaction we may prevent any activity to be undertaken by you in relation to Your Account or we may, where we reasonably believe that deliberately incorrect information has been provided by you, keep any amount deposited on Your Account following the closure of Your Account by us.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>5. Your Account Details</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.1. As part of our security procedures, on opening an Account you may be required to provide us with a username and password (&ldquo;Account Details&rdquo;). &nbsp;You must treat your Account Details as confidential and must not disclose them to any third party.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.2. You shall be responsible for any misuse and/or unauthorised disclosure of your Account Details. &nbsp;If you are concerned that your Account Details have been deliberately or unintentionally disclosed to a third party, or that Your Account has been accessed by any third party without your knowledge or consent, you must notify customer service immediately to de-activate Your Account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.3. We may require you to change your Account Details from time to time or we may suspend Your Account if we have reason to believe that there is likely to be a breach of security or misuse of the Website. We may, at our sole and absolute discretion, change your Account Details on prior notice to you.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.4. We may at any time set off any positive balance on Your Account against any amount owing by you to us for any reason, including but not limited to, re-settling of bets, payment errors or negative balances.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.5. To the extent required by your local law or tax or other authorities you are responsible for reporting your winnings and losses arising from your activities on our Website.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.6. All winnings from settled bets will be credited to the balance of Your Account. Should funds be credited to Your Account in error, it is your responsibility to inform us without delay. We reserve the right to void bets placed using funds credited to Your Account in error and to recover such funds by account adjustment at any time.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.7. It is your responsibility to actively maintain your account with us. To maintain an active Account you must log into our Website and utilise our Services at least once in any six (6) month period.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.8. Provided all funds in Your Account are confirmed by us as cleared, all ID Checks have been completed by us to our satisfaction and any conditions of withdrawal that may be applied to Your Account being met you may request a withdrawal of funds from Your Account at any time. &nbsp;Details of how to withdraw funds can be found in payments section of the Website.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.9 Funds must only be returned to the account owner of the original funds. It is NOT permitted to withdraw or refund funds to a third party account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>5.10 Withdrawal requests must first be made to the same method that was used to deposit. We will use a proportionate withdrawal rule when funds are added from multiple sources. Withdrawal request to any source other than the original deposit method will be subject to additional fraud and AML verification and may be rejected at our discretion.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>6. Suspension of Your Account Suspension and Closure of Your Account by Us</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.1. We shall be entitled to suspend Your Account in the circumstances expressly set out in these Terms and Conditions or at our sole and absolute discretion as the circumstances may reasonably warrant. Upon the suspension of Your Account:</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>(a) No bonuses or contingent winnings will be credited to the Account; and</p>
            <p style={normalText}>(b) We shall address the issue that has given rise to the Account suspension with a view to resolving it as soon as reasonably practicable so that the Account can, as appropriate, either be re-activated or closed.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.2. We reserve the right, at our sole and absolute discretion, to close your account and terminate these Terms and Conditions with us (notwithstanding any other provisions contained in these Terms and Conditions) on written notice (or attempted notice) to you using your contact details. In the event of any such termination by us we shall, subject to paragraph 6.3, as soon as reasonably practicable following a request by you, refund the balance of Your Account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.3. Where we close Your Account and terminate the Terms and Conditions pursuant to paragraph 9 or paragraph 6.2, the balance of Your Account will be non-refundable and deemed to be forfeited by you. Closure of Your Account and termination of the Terms and Conditions, other than pursuant to paragraphs 9 or 6.2 of these Terms and Conditions, will not affect any outstanding bets, provided that such outstanding bets are valid. For the avoidance of doubt, we will not credit any bonuses into Your Account, nor will you be entitled to any contingent winnings, at any time after the date on which it has been closed (whether by us pursuant to the Terms and Conditions, or in response to your request).</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.4. You may cancel Your Account with us at any time by informing us in writing of your intention to do so by contacting us by email at support@espotz.online. &nbsp;If you decide to cancel Your Account, you must stop using the Website immediately. Only after notification by us that Your Account has been cancelled will bets or requests made online with the Account be deemed void. You will continue to be liable for any activity on Your Account until such notification of Account cancellation from us.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.5. Upon any termination of Your Account under this paragraph 10 we shall be entitled (without limiting our rights under paragraph 6.2) to withhold, from the repayment of the outstanding balance on Your Account, any funds pursuant to paragraph 9, paragraph 17 or as otherwise provided by these Terms and Conditions or as required by all applicable laws and regulations.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>6.6. When repaying the outstanding balance on Your Account, we shall use the same option of payment which you provided upon registration of Your Account, or such other payment option as we may reasonably select.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>7. Events Outside Our Control</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>7.1. Without prejudice to our obligations contained within the various laws and regulations of the Canada, we are not liable for any loss or damage that you may suffer caused by events outside our reasonable control, including (without limitation) power failure; trade or labour dispute; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party or otherwise outside of our control. In such an event, we reserve the right to cancel or suspend the Services without incurring any liability.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>7.2. We are not liable for the failure of any equipment or software howsoever caused, wherever located or administered, or whether under our direct control or not, that may prevent the operation of the Services or prevent you from being able to contact us.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>7.3. We will not be liable for any failure to perform by any third party.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>8. Virus, Hacking and Other Offences</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.1. You shall not attempt to attack, hack, make unauthorized alterations to/or introduce any kind of malicious code to the Website. As such, you will not, without limitation:</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.1.1 Reverse engineer or decompile (in whole or in part) any software available through the Website; or</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.1.2 Make copies, modify, reproduce, transmit, alter, use or distribute all or any part of the Website or any material or information contained on it; or</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.1.3 Disclose account information to any third party; or (d) contravene any of the provisions stated herein; or</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.1.4 Cheat, deceive, trick, misinform or defraud us in any way through use or abuse of our services or the Website or.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>8.2. Should we suspect that you have failed to abide by the terms of this provision we shall have the right, in addition to any other remedy, to freeze or suspend your account with us in order to carry out further investigations. If it is confirmed that you failed to abide by the terms of this provision, this will result in the termination of the Services to you, closure of your account and forfeiture by us of any amounts in your account.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>9. Complaints and Arbitration</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>9.1. If you wish to raise a complaint about any aspect of our products or services please contact our customer service team (support@espotz.online) with details about your complaint, which will be escalated as necessary within our customer service team until resolution.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>9.2. If you do not agree with the decision of our customer service team, you may then request that the matter is escalated further. In order to escalate a complaint, you will need to clearly state your reasons for not agreeing with the previous decision, and any other relevant information. The decision of our Head of customer service (having completed such escalation as we consider reasonable in the circumstances) will be considered as our final decision.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>10. Breaches of the Terms and Conditions</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>We may suspend or cancel at any time Your Account if you breach any of your obligations under these Terms and Conditions. In case of your material breach of any of the provisions of these terms, as determined by us in our sole and absolute discretion, we reserve all our rights and remedies against you under these terms and at law. Further, we reserve the right to retain any outstanding funds from you as a guarantee of your obligations hereunder and/or any liability arising from the said breach of these Terms and Conditions.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>11. Use of Cookies on the Website</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>11.1. Customers should note that personal information and data may be automatically collected and stored during the registration process through the use of Cookies. A &quot;cookie&quot; is a small piece of information sent by a web server to a web browser, which enables the server to collect information from the browser. We use cookies to keep track of your browsing patterns and to build up a demographic profile. By monitoring such patterns and collating the captured data we are able to improve our service to you.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>11.2. If you object to cookies or want to delete any cookies that are already stored on Your Device, we recommend that you follow the instructions for deleting existing cookies and disabling future cookies on your file management and internet browsing software. Please note that by deleting our cookies or disabling future cookies you may not be able to access certain areas or features of the Website.</p>
            <p style={normalText}>11.3 Please note that we retain records of all customer support conversations conducted through our AI-powered chat system, which is operated using large language models (LLM). Personal information shared during customer interactions with the AI-powered chatbot, may be disclosed or shared solely for the purpose of improving and refining the chatbot&apos;s functionality and enhancing the overall customer experience.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>12. Limitation of Liability</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.1. Winnings will be credited or loses reflected to Your Account following confirmation of the final result from the relevant, reasonably appropriate, source (which in the case of a sporting event is most likely to be the sports governing body).</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.2. Should funds be credited to or debited from Your Account in error, it is your responsibility to notify us of the error without delay. Any sums credited to you due to the error will be deemed invalid and must be returned to us. You may not dispose of or place bets using funds credited to Your Account in error and we reserve the right to void any Bets involving such funds. &nbsp;You hereby agree to the return of any erroneously credited funds that you may have withdrawn and winnings from Bets placed using such funds.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.3. We will not be held responsible for any typographical, technical, or human error in posting odds and/or handicaps. In the event of error, we reserve the right at its sole discretion to either void any affected bets at any time or to correct the error.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.4. We will under no circumstances be held liable in equity, contract, tort, negligence, or otherwise (or any theory of law) for any damages or losses whether direct, indirect or any other nature, without limitation, that are deemed or alleged to have resulted from or been caused by the Services, the Website or its content including, but without limitation to, errors, inaccuracies or ambiguity in the Website or its contents, failures, malfunctions, delays or interruptions in operation or transmission, communication line failure, any persons, use or misuse of the Website or its content, any errors or omissions in content, damage for loss of business, loss of profits, business interruption, loss of business information, or any other pecuniary or consequential loss or damage (even where you have notified us of the possibility of such loss or damage).</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.5. We will not be responsible for any breach of these Terms and Conditions caused by circumstances beyond our reasonable control.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>12.6. We reserve the right to withdraw the Website or elements of the Website at any time, and save for any rights that you may have over deposited funds in its account, we will not be liable to you in any other way as a result of any such action.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>13. Indemnity</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>You agree to fully indemnify, defend and hold us, and our officers, directors, employees, consultants, advisors, agents and suppliers harmless, immediately upon demand, from and against all claims, liabilities, damages, losses, costs and expenses, including legal fees, (regardless of theory of law) arising out of your breach of any of the terms or the rules and regulations or any other liabilities arising out of your use of the Website or use by any other person accessing the Website using your Personal Information details.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>14. Law and Jurisdiction</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>The construction, validity and performance of this agreement will be governed by the laws of the Canada. However, this shall not prevent us from bringing any action in the court of any other jurisdiction for injunctive or similar relief. The English language version of the Terms and Conditions will prevail over any other language version issued by us.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}><strong>15. Intellectual Property</strong></p>
            <p style={normalText}><br /></p>
            <p style={normalText}>15.1. The contents of the Website are protected by international copyright laws and other intellectual property rights. The owner of these rights is eSpotz or other third party licensors.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>15.2. Except to the extent required to use a product for the purpose of placing Bets, no part of the Website may be reproduced or stored, modified, copied, republished, uploaded, posted, transmitted or distributed, by any means or in any manner, or included in any other website or in any public or private electronic retrieval system or service including text, graphics, video, messages, code and/or software without our express prior written consent.</p>
            <p style={normalText}><br /></p>
            <p style={normalText}>15.3. Any commercial use or exploitation of the Website or its content is strictly prohibited.</p>
          </div>

      </section>
    );
  }
}